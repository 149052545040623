/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Client } from "@twilio/conversations";
import { Dispatch } from "redux";
import log from "loglevel";
import axios from "axios";

import { initMessagesListener } from "./listeners/messagesListener";
import { initParticipantsListener } from "./listeners/participantsListener";
import { initConversationListener } from "./listeners/conversationListener";
import { ConfigState, EngagementPhase } from "../definitions";
import { initClientListeners } from "./listeners/clientListener";
import { notifications } from "../../notifications";
import { ACTION_START_SESSION, ACTION_LOAD_CONFIG } from "./actionTypes";
import {
    addNotification,
    changeEngagementPhase,
    fetchOrganization,
    fetchOrganizationFailure,
    fetchOrganizationSuccess
} from "./genericActions";
import { MESSAGES_LOAD_COUNT } from "../../constants";

export function initConfig(config: ConfigState) {
    return {
        type: ACTION_LOAD_CONFIG,
        payload: config
    };
}

export function initSession({ token, conversationSid }: { token: string; conversationSid: string }) {
    return async (dispatch: Dispatch) => {
        let conversationsClient: Client;
        let conversation;
        let participants;
        let users;
        let messages;

        try {
            conversationsClient = await Client.create(token);
            const temp = await conversationsClient.getSubscribedUsers();
            console.log(temp);
            try {
                conversation = await conversationsClient.getConversationBySid(conversationSid);
            } catch (e) {
                console.log(e);
                dispatch(addNotification(notifications.failedToInitSessionNotification("Couldn't load conversation")));
                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                return;
            }

            participants = await conversation.getParticipants();
            users = await Promise.all(participants.map(async (p) => p.getUser()));
            messages = (await conversation.getMessages(MESSAGES_LOAD_COUNT)).items;
        } catch (e) {
            log.error("Something went wrong when initializing session", e);
            throw e;
        }

        dispatch({
            type: ACTION_START_SESSION,
            payload: {
                token,
                conversationSid,
                conversationsClient,
                conversation,
                users,
                participants,
                messages,
                conversationState: conversation.state?.current,
                currentPhase: EngagementPhase.MessagingCanvas
            }
        });

        initClientListeners(conversationsClient, dispatch);
        initConversationListener(conversation, dispatch);
        initMessagesListener(conversation, dispatch);
        initParticipantsListener(conversation, dispatch);
    };
}

export function fetchOrgData({ config }: { config: any }) {
    const backend = process.env.BACKEND_URL;
    return async (dispatch: Dispatch) => {
        dispatch(fetchOrganization(""));
        try {
            const response = await axios.post(`${backend}/organization/webchat/organization`, {
                id: config.chatId
            });
            dispatch(
                fetchOrganizationSuccess({
                    ...config,
                    organization: response.data?.data
                })
            );
        } catch (error) {
            dispatch(fetchOrganizationFailure(""));
        }
    };
}
