/* eslint-disable no-console */
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button } from "@twilio-paste/core/button";

import { containerStyles, titleStyles } from "./styles/Header.styles";
import { AppState } from "../store/definitions";
import { ACTION_CLEAR_ALL_MESSAGES, ACTION_UPDATE_CONVERSATION_STATE } from "../store/actions/actionTypes";
import { resetChat } from "../store/actions/genericActions";
import { persistor } from "../store/store";
import { LOCAL_STORAGE_ITEM_ID } from "../sessionDataHandler";

export const Header = ({ customTitle }: { customTitle?: string }) => {
    const { currentPhase } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded
    }));
    const { messageLength, conversationState } = useSelector(({ chat }: AppState) => ({
        messageLength: chat.messages?.length,
        conversationState: chat.conversationState
    }));
    const dispatch = useDispatch();

    const endChatSession = () => {
        dispatch({
            type: ACTION_UPDATE_CONVERSATION_STATE,
            payload: { conversationState: "closed" }
        });
        persistor.flush().then(async () => {
            return persistor.purge();
        });
        localStorage.removeItem(LOCAL_STORAGE_ITEM_ID);
    };

    return (
        <Box as="header" {...containerStyles} style={{ background: "white", justifyContent: "center" }}>
            {conversationState && conversationState !== "closed" && (
                <Button
                    onClick={() => {
                        if (messageLength && messageLength < 25) {
                            dispatch(resetChat(messageLength));
                        } else {
                            endChatSession();
                        }
                    }}
                    variant="primary"
                    type="submit"
                    data-test="refresh-chat-button"
                >
                    <FontAwesomeIcon icon={faRotateRight} style={{ color: "white", height: "10px" }} />
                </Button>
            )}
            <Text as="h2" {...titleStyles} style={{ color: "black" }}>
                {currentPhase === "MessagingCanvas" && (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center˝" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FontAwesomeIcon
                                icon={faCircle}
                                style={{ height: "10px", color: "#28e428", marginRight: "4px" }}
                            />
                            {customTitle || "Connected"}
                        </div>
                    </div>
                )}
            </Text>
        </Box>
    );
};
