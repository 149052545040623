/* eslint-disable no-console */
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Box } from "@twilio-paste/core/box";
import { TextArea } from "@twilio-paste/core/textarea";
import { FormEvent } from "react";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { Alert } from "@twilio-paste/core/alert";

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { introStyles, fieldStyles, titleStyles, formStyles } from "./styles/PreEngagementFormPhase.styles";
import PreviewIllustration from "../assets/PreviewIllustration";

export const PreEngagementFormPhase = () => {
    const { name, email, query } = useSelector((state: AppState) => state.session.preEngagementData) || {};
    const { organization } = useSelector((state: AppState) => state.config) || {};
    const dispatch = useDispatch();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName: "You",
                    email: "engineering@halatech.io",
                    query: "",
                    organization
                }
            });
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <div style={{ marginBottom: "45px", display: "flex", justifyContent: "center" }}>
                    <PreviewIllustration
                        style={{
                            height: "196px",
                            width: "280px"
                        }}
                    />
                </div>

                <Alert variant="neutral" element="PREVIEW_ALERT">
                    <strong>Preveiew chatbot.</strong> test the behaviour and response of your Halatech Assistant. The
                    more relevant information you add in the FAQ Datastore, the more knowledgeable the Assistant becomes
                </Alert>
                <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                    Start Chat
                </Button>
            </Box>
        </>
    );
};
