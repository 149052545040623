/* eslint-disable multiline-comment-style */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from "react-redux";
import { CustomizationProvider, CustomizationProviderProps } from "@twilio-paste/core/customization";
import { CSSProperties, FC, useEffect, useState } from "react";
import { Theme } from "@twilio-paste/core/theme";
import root from "react-shadow";
import { CreateCacheOptions } from "@twilio-paste/styling-library";

import { RootContainer } from "./RootContainer";
import { AppState, EngagementPhase } from "../store/definitions";
import { sessionDataHandler } from "../sessionDataHandler";
import { fetchOrgData, initSession } from "../store/actions/initActions";
import { changeEngagementPhase } from "../store/actions/genericActions";
import { store } from "../store/store";

const AnyCustomizationProvider: FC<CustomizationProviderProps & { style: CSSProperties }> = CustomizationProvider;

export function WebchatWidget({ config }: any) {
    const dispatch = useDispatch();
    root.div = () => <div />;

    const [emotionCache, setEmotionCache] = useState<CreateCacheOptions>();
    const [showWidget, setShowWidget] = useState<boolean>(false);

    function setShadowRef(ref: Node | undefined | null): void {
        if (ref && !emotionCache) {
            const createdEmotionWithRef: CreateCacheOptions = {
                container: ref,
                key: "shadow-dom-paste"
            };
            setEmotionCache(createdEmotionWithRef);
        }
    }

    // Fetch organization when the chat widget loads.
    useEffect(() => {
        const storageChatConfig = JSON.parse(localStorage.getItem("chatConfig") as string);
        if (config.chatId) {
            dispatch(fetchOrgData({ config }));
            setShowWidget(true);
        } else if (storageChatConfig?.chatId) {
            const previewConfig = { chatId: storageChatConfig?.chatId };
            dispatch(fetchOrgData({ config: previewConfig }));
            setShowWidget(true);
        }
    }, [config, dispatch]);

    useEffect(() => {
        const data = sessionDataHandler.tryResumeExistingSession();
        if (data) {
            try {
                dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
            } catch (e) {
                // if initSession fails, go to changeEngagement phase - most likely there's something wrong with the store token or conversation sis
                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
            }
        } else {
            // if no token is stored, got engagement form
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    }, [dispatch]);

    return (
        <root.div className="shadow-dom-container">
            <div ref={setShadowRef} />
            {emotionCache && showWidget ? (
                <Theme.Provider theme="default" cacheProviderProps={emotionCache}>
                    <AnyCustomizationProvider
                        baseTheme="default"
                        theme={config.theme?.overrides}
                        elements={{
                            MESSAGE_INPUT: {
                                boxShadow: "none!important" as "none"
                            },
                            MESSAGE_INPUT_ELEMENT: {
                                "::placeholder": {
                                    fontStyle: "normal"
                                }
                            },
                            MESSAGE_INPUT_BOX: {
                                display: "inline-block",
                                boxShadow: "none"
                            },
                            ALERT: {
                                paddingTop: "space30",
                                paddingBottom: "space30"
                            },
                            BUTTON: {
                                boxShadow: "shadowBorderBottomNewWeaker",

                                "&[aria-disabled='true'][color='colorTextLink']": {
                                    color: "colorTextLinkWeak"
                                },
                                ":hover": {
                                    color: "rgb(138, 197, 63)",
                                    boxShadow: "rgb(138, 197, 63) 0px 0px 0px 1px"
                                }
                            },
                            PREVIEW_ALERT: {
                                marginBottom: "space120"
                            },
                            ENTRY_POINT: {
                                ":hover": {
                                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 6px, rgba(0, 0, 0, 0.2) 0px 4px 16px",
                                    transform: "scale(1.2)"
                                }
                            }
                        }}
                        style={{ minHeight: "100%", minWidth: "100%" }}
                    >
                        <RootContainer />
                    </AnyCustomizationProvider>
                </Theme.Provider>
            ) : null}{" "}
        </root.div>
    );
}
