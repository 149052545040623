import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import { ChatReducer } from "./chat.reducer";
import { ConfigReducer } from "./config.reducer";
import { NotificationReducer } from "./notification.reducer";
import { SessionReducer } from "./session.reducer";

const typeWindow = window as unknown as { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose };

const composeEnhancers = typeWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["session"]
};

const reducers = {
    chat: ChatReducer,
    config: ConfigReducer,
    notifications: NotificationReducer,
    session: SessionReducer
};

const persistedReducer = persistReducer(persistConfig, combineReducers(reducers));

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

export const persistor = persistStore(store);
