import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

export const formStyles: BoxStyleProps = {
    padding: "space80",
    // paddingTop: "space80",
    overflow: "auto"
};

export const titleStyles: TextStyleProps = {
    fontSize: "fontSize50",
    marginBottom: "space60",
    fontWeight: "fontWeightBold"
};

export const introStyles: TextStyleProps = {
    marginBottom: "space70"
};

export const fieldStyles: BoxStyleProps = {
    marginBottom: "space70"
};
