import { Box } from "@twilio-paste/core/box";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import { AppState, EngagementPhase } from "../store/definitions";
import { PreEngagementFormPhase } from "./PreEngagementFormPhase";
import { LoadingPhase } from "./LoadingPhase";
import { EntryPoint } from "./EntryPoint";
import { innerContainerStyles, outerContainerStyles } from "./styles/RootContainer.styles";
import { changeExpandedStatus } from "../store/actions/genericActions";

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.PreEngagementForm:
        default:
            return <PreEngagementFormPhase />;
    }
};

export function RootContainer() {
    const { currentPhase, expanded } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded
    }));
    const [toggleWidget, setToggleWidget] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleTogglePreview = () => {
            setToggleWidget((prevToggleWidget: boolean) => !prevToggleWidget);
            dispatch(changeExpandedStatus({ expanded: true }));
        };

        window.addEventListener("togglePreview", handleTogglePreview);
        return () => {
            window.removeEventListener("togglePreview", handleTogglePreview);
        };
    }, [dispatch]);

    return toggleWidget ? (
        <Box>
            <Box {...outerContainerStyles} style={{ zIndex: "1000000" }}>
                {expanded && (
                    <Box data-test="root-container" {...innerContainerStyles}>
                        {getPhaseComponent(currentPhase)}
                    </Box>
                )}
                <EntryPoint />
            </Box>
        </Box>
    ) : null;
}
