const PreviewIllustration = ({ style }: any) => (
    <svg width="514" height="516" viewBox="0 0 514 516" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path
            d="M511.413 125L457.469 160.127L474.362 170.711C464.09 187.571 410.509 258.446 410.509 258.446L349.966 264.205C327.574 307.831 276.17 384.58 276.17 384.58H118V423L288.675 421.134L371.927 291.995L430.315 290.625L499.678 183.241L513 188.343L511.413 125Z"
            fill="#DDDDDD"
            stroke="#EDEDED"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M359.33 407.883V438.773H409.33C409.33 438.773 412.33 435.903 403.23 431.473C394.64 427.293 389.46 423.213 385.15 413.403L359.33 407.883Z"
            fill="#683F1D"
        />
        <path d="M358.32 416.313H389.87L391.5 172.553L360.85 174.873L358.32 416.313Z" fill="#A7CAF0" />
        <path
            d="M366.08 205.583C370.31 204.903 371.92 203.373 371.92 203.373"
            stroke="#83ADDF"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M382.34 181.393C382.09 189.323 384.88 198.973 388.34 201.513"
            stroke="#83ADDF"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M333.08 420.773V442.513H383.08C383.08 442.513 386.08 439.643 376.97 435.213C368.39 431.033 363.21 426.953 358.9 417.143L333.08 420.773Z"
            fill="#683F1D"
        />
        <path
            d="M383.08 442.532C383.08 442.532 386.08 439.662 376.97 435.232C368.39 431.052 363.21 426.972 358.9 417.162"
            stroke="#91613D"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M317.08 146.393C312.71 205.573 314.98 245.393 318.88 295.903C322.78 346.413 329.08 420.773 329.08 420.773H362.08L364.5 167.773L317.08 146.393Z"
            fill="#A7CAF0"
        />
        <path
            d="M362.05 420.793L364.03 208.473"
            stroke="#83ADDF"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M484.13 117.393C484.13 126.953 480.55 135.463 474.97 140.983C471.457 144.585 466.801 146.854 461.8 147.403C461.46 147.403 461.12 147.453 460.8 147.463H459.99C449.85 147.463 441.16 139.703 437.57 128.703C436.378 125.041 435.777 121.213 435.79 117.363C435.79 108.103 439.15 99.8325 444.43 94.3125C446.409 92.1461 448.81 90.4076 451.486 89.2037C454.162 87.9999 457.056 87.3563 459.99 87.3125C473.3 87.3025 484.13 100.773 484.13 117.393Z"
            fill="#FED81D"
        />
        <path
            d="M474.96 140.993C471.447 144.595 466.792 146.864 461.79 147.413C461.45 147.413 461.11 147.463 460.79 147.473H459.98C449.84 147.473 441.15 139.713 437.56 128.713C448.76 135.263 453.12 148.623 472.77 141.793C473.44 141.573 474.19 141.303 474.96 140.993Z"
            fill="#FCC71F"
        />
        <path
            d="M472.72 141.772C471.367 143.392 469.702 144.722 467.824 145.684C465.947 146.646 463.894 147.22 461.79 147.372C461.45 147.372 461.11 147.422 460.79 147.432H459.98C456.999 147.433 454.035 146.981 451.19 146.092C433.44 140.832 413.51 127.902 400.62 119.832L400.44 119.722C397.44 117.822 394.79 116.212 392.67 115.042C392.32 114.832 391.98 114.652 391.67 114.482C386.92 111.962 383.08 106.322 380.73 99.7424C380.28 98.4724 379.88 97.1624 379.54 95.8324C375.39 79.4424 380.44 60.1524 402.8 67.8324C407 69.2624 412.55 72.2224 418.74 76.0824C427.605 81.7109 436.176 87.7888 444.42 94.2924C459.01 105.682 471.86 117.432 474.68 122.492C476.226 125.558 476.863 129.002 476.516 132.419C476.169 135.835 474.852 139.08 472.72 141.772Z"
            fill="#FED81D"
        />
        <path
            d="M474.96 140.993C471.447 144.595 466.792 146.865 461.79 147.413C461.45 147.413 461.11 147.463 460.79 147.473H459.98C456.999 147.474 454.035 147.022 451.19 146.133C433.44 140.873 413.51 127.943 400.62 119.873C400.62 119.873 400.56 119.813 400.44 119.763C397.44 117.863 394.79 116.253 392.67 115.083C392.32 114.873 391.98 114.693 391.67 114.523C386.92 112.003 383.08 106.363 380.73 99.7833C380.28 98.5133 379.88 97.2033 379.54 95.8733C386.86 94.6933 395.37 95.3933 404.83 99.4833C408.45 101.053 415.21 103.923 411.4 109.033C406.61 115.473 410.59 125.373 424.97 125.373C429.398 125.303 433.759 126.459 437.57 128.713C448.77 135.263 453.13 148.623 472.78 141.793C473.44 141.573 474.19 141.303 474.96 140.993Z"
            fill="#FCC71F"
        />
        <path
            d="M418.69 76.1328C415.198 75.8687 411.686 76.1044 408.26 76.8328C402.26 78.0928 395.12 81.2028 390.7 88.6428C388.098 92.9582 384.705 96.7443 380.7 99.8028C374.65 82.8028 378.6 59.5928 402.77 67.8828C406.95 69.3128 412.5 72.2728 418.69 76.1328Z"
            fill="#FFE05C"
        />
        <path
            d="M411.34 65.5635C409.46 65.1035 412.09 58.4135 417.12 52.1135C421.85 46.1935 424.84 42.9535 426.28 44.9335C427.16 46.1535 425.85 65.0635 411.34 65.5635Z"
            fill="#F7AD2B"
        />
        <path
            d="M419.29 66.1431C413.29 57.7731 409.29 50.8631 406.47 48.6031C403.65 46.3431 401.05 45.2031 400.75 42.6031C400.666 40.6938 401.112 38.7983 402.038 37.1268C402.965 35.4553 404.336 34.073 406 33.1331C410.15 30.7231 428.59 53.3131 428.59 53.3131C428.59 53.3131 429.19 64.5531 419.29 66.1431Z"
            fill="#B25E47"
        />
        <path
            d="M389.76 20.563C393.34 17.013 397.14 17.5629 405.14 25.0429C413.14 32.5229 422.08 36.373 423.95 42.383C425.95 48.893 420.54 54.053 413.29 53.183C410.73 52.883 406.74 52.4329 410.73 43.2929L399.99 34.2929C399.99 34.2929 396.99 38.783 392.4 37.523C387.62 36.163 381.41 28.873 389.76 20.563Z"
            fill="#DDDDDD"
            stroke="#EDEDED"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M412.53 35.4736C414.26 36.3636 419.05 29.4736 416.77 28.1136C413.7 26.3036 410.79 34.5736 412.53 35.4736Z"
            fill="#B25E47"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M410.73 43.2926C411.955 41.1597 413.881 39.5167 416.18 38.6426"
            stroke="#EDEDED"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M399.99 34.2532C400.64 33.322 401.091 32.2669 401.314 31.1537C401.538 30.0405 401.53 28.8931 401.29 27.7832"
            stroke="#EDEDED"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M400.08 43.7737C401.5 46.9937 405.58 42.3137 407.26 37.7737C408.87 33.4837 408.48 32.5137 406.03 32.9637C403.58 33.4137 398.14 39.5037 400.08 43.7737Z"
            fill="#B25E47"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M403.62 29.5929C405.56 30.5929 410.96 22.9529 408.39 21.4629C404.94 19.4629 401.66 28.6029 403.62 29.5929Z"
            fill="#B25E47"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M406.69 31.2121C408.63 32.2121 414.03 24.5721 411.46 23.0821C408.01 21.0821 404.73 30.2221 406.69 31.2121Z"
            fill="#B25E47"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M409.68 33.4824C411.55 34.4824 416.68 27.1224 414.25 25.7024C410.94 23.7724 407.81 32.5424 409.68 33.4824Z"
            fill="#B25E47"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M411.34 65.5636C418.98 76.3136 439.98 107.564 447.92 118.044C458.22 131.664 482.87 133.874 482.92 107.824C482.92 94.9236 426.32 44.9336 426.32 44.9336C426.32 44.9336 423.4 60.2236 411.34 65.5636Z"
            fill="#FED81D"
        />
        <path
            d="M418.61 68.7739C428 65.6139 431.22 55.0039 431.22 55.0039"
            stroke="#F7AD2B"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M422.97 49.5332C436.97 68.8932 441.97 82.1232 438.64 87.3032C435.31 92.4832 427.8 89.6832 428.64 85.3032C429.64 79.8732 438.71 78.4932 434.97 93.0732C431.23 107.653 420.97 108.923 419.11 104.883C416.6 99.2832 427.04 93.3232 428.11 108.013C429.18 122.703 412.76 126.463 408.18 122.773C403.6 119.083 416.52 109.453 416.32 125.603C416.12 141.753 397.03 142.253 388.42 140.093"
            stroke="#EDEDED"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M411.34 65.5625C418.98 76.3125 439.98 107.563 447.92 118.043C450.285 121.126 453.313 123.638 456.78 125.393"
            stroke="#F7AD2B"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M296.64 153.964C296.684 157.765 295.834 161.524 294.16 164.937C292.485 168.35 290.033 171.322 287 173.614C283.468 176.207 279.202 177.608 274.82 177.614H274.76C272.007 177.604 269.285 177.032 266.76 175.934C258.7 172.474 253 163.934 253 153.934C253 153.734 253 153.544 253 153.364C253.047 150.019 253.782 146.72 255.158 143.672C256.534 140.624 258.523 137.891 261 135.644C263.57 133.349 266.658 131.712 270 130.874C271.595 130.468 273.234 130.263 274.88 130.264C283.53 130.264 291.01 135.734 294.53 143.654C295.314 145.393 295.888 147.219 296.24 149.094C296.533 150.7 296.667 152.331 296.64 153.964Z"
            fill="#FED81D"
        />
        <path
            d="M274.76 177.632C272.007 177.622 269.285 177.05 266.76 175.952C258.7 172.492 253 163.952 253 153.952C253 153.752 253 153.562 253 153.382C253.047 150.038 253.782 146.739 255.158 143.691C256.534 140.642 258.523 137.909 261 135.662C259.22 158.712 261.72 170.602 274.76 177.632Z"
            fill="#FCC71F"
        />
        <path
            d="M340.28 85.112C339.68 87.022 338.98 88.972 338.2 90.952C337.48 92.772 336.683 94.612 335.81 96.472C335.53 97.052 335.24 97.642 334.95 98.222C334.04 100.062 333.07 101.912 332.01 103.762C331.66 104.382 331.3 105.002 330.94 105.622C324.718 115.87 317.664 125.589 309.85 134.682C308.05 136.782 306.21 138.852 304.34 140.872L303.02 142.282C301.62 143.762 300.21 145.202 298.79 146.602C297.92 147.452 297.06 148.302 296.19 149.112C285.58 159.112 274.64 166.352 265.76 166.552C264.931 166.577 264.101 166.53 263.28 166.412C260.849 166.151 258.572 165.097 256.8 163.412C254.43 161.102 253.17 157.622 253.03 153.412C252.78 145.492 256.45 134.932 264.03 124.412L265.33 122.582C272.78 111.902 283.78 93.782 295.98 79.052C307.18 65.522 319.4 54.842 330.86 55.362C331.778 55.404 332.691 55.5211 333.59 55.712C343.54 57.772 345.01 69.972 340.28 85.112Z"
            fill="#FED81D"
        />
        <path
            d="M265.3 153.104C263.685 155 262.836 157.431 262.919 159.921C263.002 162.41 264.012 164.779 265.75 166.564C264.921 166.588 264.091 166.542 263.27 166.424C260.839 166.163 258.562 165.108 256.79 163.424C254.42 161.114 253.16 157.634 253.02 153.424C252.77 145.504 256.44 134.944 264.02 124.424L265.32 122.594C267.634 124.873 269.233 127.779 269.92 130.954C270.517 133.488 270.732 136.096 270.56 138.694C270.27 144.064 268.41 149.454 265.3 153.104Z"
            fill="#FCC71F"
        />
        <path
            d="M338.2 90.9531C336.16 96.022 333.733 100.927 330.94 105.623C324.718 115.871 317.664 125.59 309.85 134.683C306.3 138.833 302.58 142.873 298.79 146.613"
            stroke="#F7AD2B"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M340.28 85.1129C334.94 78.5629 329.02 75.0129 319.28 74.9629C313.28 74.9629 305.76 76.2529 296.03 79.0129C307.23 65.4829 319.45 54.8029 330.91 55.3229C331.828 55.365 332.741 55.482 333.64 55.6729C343.54 57.7729 345.01 69.9729 340.28 85.1129Z"
            fill="#FFE05C"
        />
        <path
            d="M408.26 76.8327C407.84 86.1027 403.88 103.703 401.11 117.143C400.93 118.043 400.75 118.953 400.57 119.893C397.12 137.613 393.66 164.133 393.64 168.143C393.64 181.533 384.37 179.143 358.45 178.383C345.7 178.033 331.6 177.563 321.08 175.453C318.112 174.914 315.205 174.083 312.4 172.973C307.4 170.873 304.46 167.913 304.97 163.673C305.036 163.144 305.146 162.622 305.3 162.113C307.816 153.163 309.342 143.965 309.85 134.683C310.22 129.683 310.42 124.543 310.66 119.283C310.85 114.823 311.07 110.283 311.44 105.473C312.17 95.9327 315.44 84.4727 319.24 74.9627C323.56 63.9627 328.47 55.5227 330.73 55.3327H330.87C335.18 55.0127 346.1 56.4727 362.09 56.7927C368.86 56.9386 375.564 58.1605 381.95 60.4127C384.75 61.4127 396.02 64.6826 402.74 67.8926C404.52 68.597 406.137 69.6589 407.49 71.0127C408.18 71.9127 408.39 73.9727 408.26 76.8327Z"
            fill="#FED81D"
        />
        <path
            d="M321.08 175.453C318.112 174.914 315.205 174.083 312.4 172.973C307.4 170.873 304.46 167.913 304.97 163.673C305.036 163.144 305.146 162.622 305.3 162.113C307.816 153.163 309.342 143.965 309.85 134.683C310.22 129.683 310.42 124.543 310.66 119.283L332.8 98.2227C338.87 108.383 327.21 128.033 319.43 133.083C311.65 138.133 312.19 148.083 328.58 151.573C341.01 154.203 328.08 168.643 321.08 175.453Z"
            fill="#FCC71F"
        />
        <path
            d="M418.69 76.1329C415.198 75.8689 411.686 76.1046 408.26 76.8329C402.26 78.0929 395.12 81.2029 390.7 88.6429C388.098 92.9583 384.705 96.7444 380.7 99.8029C370.26 107.803 356.16 110.193 347.17 95.2529C345.122 91.7177 342.829 88.3295 340.31 85.1129C334.97 78.5629 329.05 75.0129 319.31 74.9629C313.31 74.9629 305.79 76.2529 296.06 79.0129C307.26 65.4829 319.48 54.8029 330.94 55.3229C335.25 55.0029 346.17 56.4629 362.16 56.7829C368.93 56.9288 375.634 58.1507 382.02 60.4029C384.82 61.4029 396.09 64.6729 402.81 67.8829C406.95 69.3129 412.5 72.2729 418.69 76.1329Z"
            fill="#FFE05C"
        />
        <path
            d="M375.08 73.033C375.08 73.033 378.08 82.683 379.24 83.543L384.24 84.323C385.883 81.3945 387.261 78.3258 388.36 75.153L383.08 67.543L375.08 73.033Z"
            fill="#683F1D"
        />
        <path
            d="M379.28 83.543L367.75 127.543C367.75 127.543 369.08 133.423 375.67 142.363C375.67 142.363 382.34 133.663 383.75 129.893L384.3 84.323L379.28 83.543Z"
            fill="#683F1D"
        />
        <path
            d="M366.72 53.293C363.98 53.373 358.36 55.883 358.29 58.173C358.22 60.463 363.39 78.583 371.6 83.093C371.6 83.093 374.78 73.623 383.06 72.293L366.72 53.293Z"
            fill="white"
        />
        <path
            d="M383.08 72.2915C386.3 76.2495 388.974 80.6219 391.03 85.2915C391.03 85.2915 399.14 74.6815 398.03 66.1215C397.84 64.6915 390.72 61.2715 390.72 61.2715L383.08 72.2915Z"
            fill="white"
        />
        <path
            d="M400.57 119.893C400.75 118.953 400.93 118.043 401.11 117.143C402.24 111.673 403.57 105.503 404.77 99.5027C404.86 99.0627 404.94 98.6427 405.02 98.2227"
            stroke="#F7AD2B"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M392.83 63.3523C392.83 63.3523 389.54 73.0523 382.83 73.0323C371.91 73.0323 365.09 55.4823 365.09 55.4823C368.45 41.8723 368.09 35.5523 368.09 35.5523C367.72 26.5523 388.39 14.6623 391.24 41.3923L391.49 44.9323L392.37 57.3323L392.83 63.3523Z"
            fill="#B25E47"
        />
        <path d="M392.4 57.3316C385.4 57.0916 378.95 53.4316 378.61 44.9316H391.52L392.4 57.3316Z" fill="#914839" />
        <path
            d="M390.67 9.50316C390.32 11.9232 387.21 13.5032 387.21 13.5032L380.08 24.2132C380.08 24.2132 373.57 31.6532 366.64 30.8932C365.618 30.7829 364.623 30.4953 363.7 30.0432C360.28 28.3832 362.27 25.1632 361.57 24.1132C360.87 23.0632 359.19 22.9232 358.77 20.9832C358.35 19.0432 361.47 19.1832 360.14 17.0932C358.81 15.0032 358.05 13.2932 360.24 11.7832C362.43 10.2732 360.32 9.67316 361.72 7.25316C362.8 5.37316 364.6 7.65316 365.26 5.18316C365.92 2.71316 367.93 3.59315 369.37 3.36315C371.19 3.06315 369.64 1.49316 372.13 0.893158C373.34 0.593158 373.31 2.02316 374.73 1.07316C375.336 0.609216 376.058 0.321666 376.817 0.242256C377.576 0.162846 378.342 0.294661 379.03 0.623154C380.86 1.83315 380.78 -0.596842 383.03 0.143158C384.65 0.683158 383.62 1.91315 385.85 2.26315C388.08 2.61315 388.01 3.92316 388.2 5.31316C388.22 5.50798 388.25 5.7016 388.29 5.89316C388.62 7.47316 391.01 7.08316 390.67 9.50316Z"
            fill="#604730"
        />
        <path
            d="M392.21 8.3328C391.85 10.9328 388.58 12.6528 388.58 12.6528L381.08 24.0728C381.08 24.0728 374.27 32.0728 367 31.2328C367.137 30.6307 367.096 30.0016 366.88 29.4228C366.14 28.3028 364.39 28.1528 363.95 26.0728C363.51 23.9928 366.78 24.1328 365.39 21.9028C364 19.6728 363.2 17.8428 365.48 16.2128C367.76 14.5828 365.57 13.9728 367.04 11.3828C368.17 9.38281 370.04 11.8028 370.76 9.16281C371.48 6.5228 373.55 7.4628 375.06 7.2228C376.97 6.9028 375.34 5.22281 377.96 4.57281C379.23 4.25281 379.19 5.78281 380.68 4.77281C382.17 3.76281 384.07 3.52281 385.19 4.28281C387.11 5.58281 387.02 2.97281 389.42 3.77281L389.63 3.85281C389.63 4.06281 389.69 4.2628 389.73 4.4728C390.08 6.1628 392.57 5.7728 392.21 8.3328Z"
            fill="#473526"
        />
        <path
            d="M397.01 43.9433C395.08 49.3933 383.43 55.5633 369.28 37.7933C367.46 38.4733 364.42 37.9633 362.75 34.7933C360.66 30.7933 363.65 24.8933 370.61 27.7233C371.89 28.2433 372.61 25.0233 371.05 22.7833C369.49 20.5433 366.85 15.0833 373.05 11.1033C379.25 7.12327 387.51 7.54327 390.67 12.5033C392.663 15.8026 394.248 19.3318 395.39 23.0133C395.92 24.6633 396.39 26.3633 396.78 28.0833C398.08 33.8433 398.48 39.7733 397.01 43.9433Z"
            fill="#B25E47"
        />
        <path
            d="M383.82 29.4228C383.49 30.7528 380.9 31.2528 378.02 30.5428C375.14 29.8328 373.08 28.1928 373.4 26.8628C373.72 25.5328 376.32 25.0428 379.2 25.7528C382.08 26.4628 384.14 28.1028 383.82 29.4228Z"
            fill="#A0503D"
        />
        <path
            d="M396.81 28.0524C395.43 28.8124 394.16 28.9924 393.53 28.3524C392.6 27.4324 393.43 25.0424 395.42 22.9824C395.95 24.6324 396.42 26.3324 396.81 28.0524Z"
            fill="#A0503D"
        />
        <path
            d="M375.82 20.254C376.04 21.404 383.52 19.184 382.82 16.814C381.81 13.594 375.41 18.194 375.82 20.254Z"
            fill="#604730"
        />
        <path
            d="M386.71 16.7726C386.87 18.2526 392.85 18.2726 392.83 17.3126C392.81 16.3526 386.35 13.4226 386.71 16.7726Z"
            fill="#604730"
        />
        <path
            d="M381.49 23.6937C381.49 23.9608 381.569 24.2218 381.718 24.4438C381.866 24.6658 382.077 24.8388 382.324 24.941C382.57 25.0432 382.842 25.0699 383.104 25.0178C383.366 24.9657 383.606 24.8371 383.795 24.6483C383.984 24.4595 384.112 24.219 384.164 23.9571C384.216 23.6952 384.19 23.4238 384.088 23.1771C383.985 22.9304 383.812 22.7196 383.59 22.5713C383.368 22.4229 383.107 22.3438 382.84 22.3438C382.663 22.3438 382.487 22.3787 382.324 22.4465C382.16 22.5144 382.011 22.6138 381.886 22.7392C381.76 22.8645 381.661 23.0133 381.593 23.1771C381.525 23.3409 381.49 23.5165 381.49 23.6937Z"
            fill="#683F1D"
        />
        <path
            d="M389.28 22.3422C389.278 22.6116 389.356 22.8755 389.505 23.1005C389.653 23.3255 389.865 23.5013 390.113 23.6058C390.361 23.7103 390.635 23.7386 390.899 23.6873C391.164 23.636 391.407 23.5072 391.598 23.3174C391.79 23.1276 391.92 22.8853 391.973 22.6212C392.027 22.3572 392 22.0832 391.898 21.8341C391.795 21.585 391.621 21.372 391.397 21.2221C391.173 21.0723 390.91 20.9922 390.64 20.9922C390.462 20.9909 390.286 21.0248 390.121 21.0921C389.956 21.1594 389.806 21.2586 389.679 21.3841C389.553 21.5096 389.452 21.6588 389.384 21.8233C389.316 21.9877 389.28 22.1641 389.28 22.3422Z"
            fill="#683F1D"
        />
        <path
            d="M365.08 31.7739C366.08 29.1839 369.94 29.6639 371.14 32.5339"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M370.37 31.2833C368.54 30.1733 365.42 34.3533 370.37 35.5433"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M369.28 37.7726C370.482 37.4416 371.612 36.8887 372.61 36.1426"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M386.33 20.0332C386.33 25.0332 388.62 25.5932 390.92 26.4932C392.84 27.2532 392.23 29.3732 391.78 30.0632"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M383.82 33.3235C387.74 33.3235 390.7 33.1035 390.7 33.1035C390.7 33.1035 388.96 38.0435 386.7 37.7935C384.44 37.5435 383.82 33.3235 383.82 33.3235Z"
            fill="white"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M382.935 161.555L335.566 131.91L292.537 200.665L339.905 230.31L382.935 161.555Z" fill="#EDEDED" />
        <path d="M375.656 164.702L335.917 139.832L299.815 197.517L339.554 222.387L375.656 164.702Z" fill="#F9F9F9" />
        <path
            d="M366.26 147.993C366.734 147.599 367.14 147.13 367.46 146.603C367.866 145.963 368.14 145.249 368.266 144.502C368.392 143.755 368.368 142.991 368.196 142.253C368.023 141.515 367.705 140.819 367.261 140.206C366.816 139.593 366.254 139.074 365.606 138.681C364.959 138.287 364.24 138.027 363.49 137.914C362.741 137.802 361.977 137.84 361.243 138.027C360.509 138.213 359.819 138.544 359.214 139C358.609 139.456 358.101 140.029 357.72 140.683C357.406 141.207 357.177 141.778 357.04 142.373L349.85 137.993L344.35 146.993L367.96 161.373L373.45 152.373L366.26 147.993Z"
            fill="#DDDDDD"
            stroke="#EDEDED"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M363.244 146.327C364.719 145.966 365.623 144.478 365.262 143.003C364.901 141.527 363.412 140.624 361.937 140.985C360.462 141.346 359.558 142.835 359.919 144.31C360.28 145.785 361.769 146.688 363.244 146.327Z"
            fill="#F9F9F9"
        />
        <path d="M338.2 150.812L365.29 167.763" stroke="#DDDDDD" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M335.5 155.592L362.59 172.552" stroke="#DDDDDD" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M332.8 160.383L353.91 173.593" stroke="#DDDDDD" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M338.92 181.393C351.59 187.693 355.75 189.873 356.61 190.633C359.2 192.933 358.9 197.193 358.9 197.193C358.9 197.193 364.47 200.033 365.03 201.643C365.59 203.253 362.7 206.303 360.85 206.143C360.85 206.143 358.63 208.633 356.61 208.473C356.61 208.473 355.87 211.763 353.14 211.923C353.14 211.923 352.82 214.743 348.88 213.923C344.94 213.103 328.21 199.543 328.21 199.543L338.92 181.393Z"
            fill="#B25E47"
        />
        <path
            d="M340.33 182.091C339.2 184.491 332.89 196.171 330.19 201.141C329.45 202.521 328.98 203.381 328.98 203.381C328.98 203.381 311.38 196.381 295.7 189.821C286.37 185.911 277.7 182.181 273.88 180.191C271.413 178.947 269.037 177.53 266.77 175.951C261.66 172.361 258.08 168.271 256.77 163.411C255.46 158.551 256.48 152.631 260.69 145.721C261.788 143.949 263.225 142.411 264.918 141.196C266.612 139.981 268.53 139.113 270.56 138.641C272.744 138.103 274.994 137.884 277.24 137.991L277.99 138.041C278.79 138.041 279.59 138.181 280.41 138.311L281.12 138.431L281.93 138.591L283.27 138.891H283.32L284.04 139.081L284.36 139.181C284.64 139.261 284.92 139.341 285.18 139.431L286.02 139.701L286.85 139.981C287.13 140.091 287.41 140.191 287.69 140.311C289.083 140.856 290.445 141.477 291.77 142.171C292.01 142.281 292.25 142.411 292.48 142.541C292.71 142.671 293.17 142.911 293.48 143.111L294.35 143.631H294.41C295.55 144.321 296.7 145.081 297.85 145.911C298.14 146.121 298.44 146.331 298.72 146.551C300.17 147.631 301.62 148.841 303.06 150.151C319.99 165.631 339.93 181.591 340.44 181.591C340.54 181.641 340.48 181.771 340.33 182.091Z"
            fill="#FED81D"
        />
        <path
            d="M295.7 189.823C286.37 185.913 277.7 182.183 273.88 180.193C271.413 178.948 269.037 177.531 266.77 175.953C261.66 172.363 258.08 168.273 256.77 163.413C255.46 158.553 256.48 152.633 260.69 145.723C261.788 143.95 263.225 142.413 264.918 141.198C266.612 139.983 268.53 139.114 270.56 138.643C270.25 144.063 268.39 149.453 265.28 153.103C263.665 154.999 262.816 157.43 262.899 159.92C262.983 162.409 263.992 164.778 265.73 166.563C268.5 169.563 273.23 171.503 279.54 170.673C282.14 170.333 284.69 171.533 286.98 173.613C291.3 177.463 294.68 184.353 295.7 189.823Z"
            fill="#FCC71F"
        />
        <path
            d="M276.5 137.973C284.5 138.083 294.05 141.973 303.13 150.213C320.13 165.753 340.13 181.773 340.51 181.653"
            stroke="#F7AD2B"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M331.91 181.941C329.32 187.021 324.51 196.111 324.51 196.111"
            stroke="#F7AD2B"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M356.61 190.633C359.2 192.933 358.9 197.193 358.9 197.193C358.9 197.193 364.47 200.033 365.03 201.643C365.59 203.253 362.7 206.303 360.85 206.143C360.85 206.143 358.63 208.633 356.61 208.473C356.61 208.473 355.87 211.763 353.14 211.923C353.14 211.923 352.82 214.743 348.88 213.923"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M359.19 197.343L354.02 194.883"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M360.85 206.143C359.144 205.443 357.475 204.655 355.85 203.783"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M356.61 208.472C355.551 208.045 354.544 207.498 353.61 206.842"
            stroke="#934735"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M161.648 289.971C159.458 293.661 157.078 297.371 154.558 300.971C148.078 310.271 140.778 318.971 133.958 325.181C110.818 346.271 103.098 324.461 105.278 315.541C107.008 308.411 125.878 281.991 136.738 267.331C139.458 263.661 141.668 260.741 142.988 259.021C149.608 250.481 157.808 246.021 168.848 249.381C178.848 252.421 172.928 270.961 161.648 289.971Z"
            fill="#C3DCF8"
        />
        <path
            d="M161.648 289.971C159.846 288.381 158.189 286.634 156.698 284.751C150.888 277.511 147.218 269.531 136.698 267.341C139.418 263.671 141.628 260.751 142.948 259.031C149.568 250.491 157.768 246.031 168.808 249.391C178.848 252.421 172.928 270.961 161.648 289.971Z"
            fill="#D0E5F7"
        />
        <path
            d="M118.418 405.24C111.688 428.49 95.2183 474.54 95.4183 475.44C95.4783 475.85 95.8283 480.83 96.1983 486.35C96.6883 493.57 97.1983 501.72 97.1983 501.72C97.1983 501.72 51.4483 516.21 47.5283 515.16C43.6083 514.11 44.3583 508.51 50.3983 503.98C51.6683 502.98 53.5183 501.46 55.6483 499.6C63.5683 492.66 75.3083 481.49 75.3083 481.49C75.3083 481.49 85.7983 392.61 87.5283 384.4C89.2583 376.19 96.4483 367.49 107.168 368.4C118.258 369.34 125.118 381.99 118.418 405.24Z"
            fill="#FEBD9D"
        />
        <path
            d="M97.1881 501.72C97.1881 501.72 51.4381 516.21 47.5181 515.16C43.5981 514.11 44.3481 508.51 50.3881 503.98C51.6581 502.98 53.5081 501.46 55.6381 499.6C64.0081 501.33 72.9681 498.52 77.6381 495.03C83.0779 490.801 89.3904 487.836 96.1181 486.35C96.6481 493.56 97.1881 501.72 97.1881 501.72Z"
            fill="#83ADDF"
        />
        <path
            d="M272.938 286.14C273.158 284.45 273.048 282.06 268.198 279.48L266.598 286.76C266.598 286.76 272.558 289.09 272.938 286.14Z"
            fill="#A7CAF0"
        />
        <path
            d="M250.218 278.631C251.768 271.241 252.448 270.561 252.218 266.971C251.988 263.381 251.538 259.091 257.468 251.901C263.398 244.711 266.898 239.061 268.068 239.641C269.238 240.221 268.458 245.401 266.218 250.401C266.218 250.401 281.968 241.401 285.278 240.131C288.588 238.861 286.448 244.891 279.828 248.131C279.828 248.131 290.238 241.851 292.768 242.371C295.298 242.891 290.628 246.551 287.028 248.901C287.028 248.901 293.848 244.611 295.198 246.021C296.548 247.431 292.788 250.431 289.648 252.311C289.648 252.311 293.738 250.181 294.418 251.241C295.098 252.301 294.418 253.481 290.418 256.011C286.418 258.541 277.678 262.821 270.978 270.501L269.228 289.501C269.228 289.501 253.518 292.051 250.218 278.631Z"
            fill="#FEBD9D"
        />
        <path
            d="M259.938 248.87C264.408 243.19 267.108 239.15 268.108 239.64C269.278 240.23 268.498 245.4 266.258 250.4C266.258 250.4 282.008 241.4 285.318 240.13C288.628 238.86 286.488 244.89 279.868 248.13C279.868 248.13 290.278 241.85 292.808 242.37C295.338 242.89 290.668 246.55 287.068 248.9C287.068 248.9 293.888 244.61 295.238 246.02C296.588 247.43 292.828 250.43 289.688 252.31C289.688 252.31 293.778 250.18 294.458 251.24C295.138 252.3 294.458 253.48 290.458 256.01"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M267.428 262.98C268.108 257.63 271.678 255.86 274.688 255.08"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M274.388 249.811C278.688 250.001 283.198 253.971 283.488 255.431"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M276.528 250.19C276.588 250.19 282.428 246.59 282.428 246.59"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M288.148 248.141L280.808 252.411"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M290.538 251.75L284.988 254.86"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M237.238 350.111C244.688 357.621 258.928 353.061 263.968 333.111C269.008 313.161 272.968 286.111 272.968 286.111C264.678 284.289 256.941 280.518 250.398 275.111C243.118 294.881 227.568 340.351 237.238 350.111Z"
            fill="#C3DCF8"
        />
        <path
            d="M250.368 275.121C245.558 288.251 237.068 312.721 234.758 330.541"
            stroke="#83ADDF"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M91.298 374.661C87.058 395.561 79.678 412.351 71.938 430.561C64.198 448.771 56.258 471.561 56.258 475.111V491.221C56.258 491.221 4.598 500.001 2.258 500.001C-0.0820024 500.001 -1.742 495.001 3.258 491.431C4.508 490.551 7.048 488.631 10.168 486.251C19.648 479.051 34.418 467.581 34.418 467.581C34.418 467.581 52.108 393.891 56.178 370.171C62.818 331.881 97.028 346.331 91.298 374.661Z"
            fill="#FEBD9D"
        />
        <path
            d="M84.438 399.392C86.208 394.302 87.828 389.082 89.228 383.602C96.958 385.182 106.228 386.492 117.828 388.552"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M56.258 476.4V491.2C56.258 491.2 4.598 499.98 2.258 499.98C-0.0820024 499.98 -1.742 494.98 3.258 491.41C4.508 490.53 7.048 488.61 10.168 486.23C19.548 488.38 30.618 484.81 36.598 482.12C42.8416 479.277 49.4633 477.35 56.258 476.4Z"
            fill="#83ADDF"
        />
        <path
            d="M114.818 334.309C124.533 334.309 132.408 327.257 132.408 318.559C132.408 309.86 124.533 302.809 114.818 302.809C105.103 302.809 97.228 309.86 97.228 318.559C97.228 327.257 105.103 334.309 114.818 334.309Z"
            fill="#C3DCF8"
        />
        <path
            d="M226.608 257.469C225.965 257.874 225.297 258.238 224.608 258.559C213.718 263.789 207.898 262.279 206.808 257.129C205.718 251.979 194.318 255.859 193.648 246.759C192.978 237.659 197.018 234.689 196.258 230.049C196.258 230.049 196.788 228.849 197.598 227.429C199.308 224.429 202.288 220.579 204.508 224.509C207.798 230.329 209.838 229.709 215.908 230.389C221.978 231.069 224.768 235.279 222.908 238.319C220.648 242.019 221.848 243.619 228.478 244.229C234.438 244.779 235.348 252.229 226.608 257.469Z"
            fill="#DD6819"
        />
        <path
            d="M226.608 257.47C225.965 257.874 225.297 258.238 224.608 258.56C213.718 263.79 207.898 262.28 206.808 257.13C205.718 251.98 194.318 255.86 193.648 246.76C192.978 237.66 197.018 234.69 196.258 230.05C196.258 230.05 196.788 228.85 197.598 227.43C205.598 229.89 207.878 234.09 207.098 237.72C206.098 242.34 210.168 248.41 220.278 247.58C225.348 247.16 226.618 252.29 226.608 257.47Z"
            fill="#CC5E0D"
        />
        <path
            d="M220.948 386.87C217.029 392.308 211.754 396.625 205.648 399.39C205.648 399.39 205.648 397.89 205.298 395.39C204.128 385.77 199.148 361.39 178.158 349.14L176.688 348.87H176.558L166.118 346.93C166.118 346.93 163.398 336.07 161.438 327.56C159.478 319.05 154.698 311.45 154.518 302.47C154.518 301.97 154.518 301.47 154.518 300.98C154.588 295.14 155.818 289.56 156.658 284.75C157.033 282.768 157.287 280.764 157.418 278.75C157.698 272.57 152.828 265.65 152.358 261.34C151.888 257.03 152.358 247.95 163.688 248.91C165.538 249.06 167.228 249.21 168.808 249.36C170.388 249.51 172.068 249.7 173.498 249.88C174.008 249.94 174.498 250.02 174.998 250.1C177.002 250.37 178.984 250.785 180.928 251.34C182.487 251.788 183.991 252.412 185.408 253.2C189.798 255.65 199.848 252.52 201.078 252.13H201.198C203.378 251.99 205.308 251.83 207.028 251.68C208.748 251.53 210.178 251.41 211.478 251.28C215.298 250.88 217.548 250.55 218.718 250.41C219.408 250.41 220.398 259.8 221.458 272.41C222.038 279.27 222.628 287.08 223.208 294.85C224.388 311 225.498 327 226.148 333.85C226.248 334.96 226.348 335.85 226.418 336.44C227.458 343.75 233.568 369.07 220.948 386.87Z"
            fill="#C3DCF8"
        />
        <path
            d="M221.508 272.399C218.344 274.344 215.362 276.569 212.598 279.049C201.438 288.829 193.428 300.189 176.598 296.949C171.084 296.015 165.929 293.595 161.688 289.949C159.886 288.36 158.229 286.613 156.738 284.729C157.113 282.746 157.367 280.743 157.498 278.729C157.778 272.549 152.908 265.629 152.438 261.319C151.968 257.009 152.438 247.929 163.768 248.889C165.618 249.039 167.308 249.189 168.888 249.339C170.468 249.489 172.148 249.679 173.578 249.859C176.089 250.146 178.574 250.634 181.008 251.319C182.567 251.766 184.07 252.39 185.488 253.179C189.878 255.629 199.928 252.499 201.158 252.109H201.278C205.558 251.819 208.928 251.519 211.558 251.259C215.378 250.859 217.628 250.529 218.798 250.389C219.458 250.399 220.448 259.789 221.508 272.399Z"
            fill="#D0E5F7"
        />
        <path
            d="M195.628 323.419C196.974 333.34 198.942 343.166 201.518 352.839L176.688 348.839H176.558C176.558 348.839 175.858 332.839 171.148 318.199C168.693 310.47 167.676 302.356 168.148 294.259C168.599 287.5 169.714 280.801 171.478 274.259C173.352 266.354 174.536 258.3 175.018 250.189C176.158 250.349 177.208 250.529 178.198 250.739L178.878 250.889C179.611 251.049 180.301 251.229 180.948 251.429C182.508 251.877 184.011 252.501 185.428 253.289C189.818 255.739 199.868 252.609 201.098 252.219H201.218C202.578 252.119 203.848 252.039 205.018 251.939L205.718 251.879L207.048 251.769C205.168 257.539 197.388 281.489 194.848 294.119C194.578 295.519 194.358 296.779 194.218 297.849C193.488 303.609 194.248 313.439 195.628 323.419Z"
            fill="white"
        />
        <path
            d="M207.118 251.67C205.238 257.44 197.458 281.39 194.918 294.02C189.411 297.294 182.861 298.342 176.608 296.95C173.707 296.41 170.887 295.499 168.218 294.24C168.668 287.48 169.784 280.781 171.548 274.24C173.421 266.334 174.606 258.281 175.088 250.17C176.228 250.33 177.278 250.51 178.268 250.72L178.948 250.87C179.681 251.03 180.371 251.21 181.018 251.41C182.577 251.857 184.08 252.481 185.498 253.27C189.888 255.72 199.938 252.59 201.168 252.2H201.288C202.648 252.1 203.918 252.02 205.088 251.92L205.788 251.86L207.118 251.67Z"
            fill="#F9F9F9"
        />
        <path
            d="M206.198 280.67L211.998 293.9C210.338 296.73 200.098 315.3 195.628 323.42C194.248 313.42 193.488 303.61 194.238 297.75C195.628 286.89 204.978 258.08 207.068 251.67C208.748 251.54 210.218 251.4 211.518 251.27L213.298 276.39C211.998 277.25 206.198 280.67 206.198 280.67Z"
            fill="#A7CAF0"
        />
        <path
            d="M171.498 274.2C169.368 282.81 165.148 299.5 171.148 318.2C166.127 310.886 162.553 302.678 160.618 294.02L167.118 279.48L163.798 275.12C163.798 275.12 167.408 265.12 173.498 249.91C174.008 249.97 174.498 250.05 174.998 250.13C174.529 258.24 173.358 266.293 171.498 274.2Z"
            fill="#A7CAF0"
        />
        <path
            d="M204.268 250.559C206.698 249.839 208.718 250.559 208.668 251.559C208.548 254.229 203.888 270.349 201.878 271.169C201.508 271.319 199.198 263.899 196.578 262.999C193.958 262.099 189.118 263.329 189.228 263.269C189.338 263.209 199.038 248.879 204.268 250.559Z"
            fill="#683F1D"
        />
        <path
            d="M181.118 249.98C185.228 255.52 184.668 259.68 183.168 259.87C181.668 260.06 179.838 259.92 178.028 261.82C176.218 263.72 173.348 266.82 173.128 266.42C172.058 264.42 173.878 251.42 174.678 250.07C175.668 248.41 180.008 248.48 181.118 249.98Z"
            fill="#683F1D"
        />
        <path
            d="M184.288 269.379C183.788 279.949 181.338 283.999 179.858 290.519C178.378 297.039 179.688 305.919 182.078 316.149C184.468 326.379 184.338 331.889 184.288 333.009C184.238 334.129 181.878 340.549 187.048 343.009"
            stroke="#DDDDDD"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M204.278 250.559C204.278 250.559 188.388 266.789 184.668 264.379C180.948 261.969 179.518 252.759 179.928 249.669C180.078 248.669 180.358 246.769 180.668 244.669C181.318 240.449 182.128 235.309 182.268 234.199C182.698 225.199 197.458 217.449 201.678 227.419C201.068 235.228 201.95 243.081 204.278 250.559Z"
            fill="#FEBD9D"
        />
        <path
            d="M180.668 244.629C181.318 240.409 182.128 235.269 182.268 234.159L191.878 230.809C192.888 239.019 188.718 243.679 180.668 244.629Z"
            fill="#F4A789"
        />
        <path
            d="M154.558 300.979C154.628 295.139 155.858 289.559 156.698 284.749C157.073 282.767 157.327 280.763 157.458 278.749C157.588 275.749 156.538 272.639 155.348 269.699"
            stroke="#83ADDF"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M204.768 353.56C198.648 352.1 178.158 349.15 178.158 349.15"
            stroke="#83ADDF"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M78.2479 344.4C67.5379 344.4 51.5379 361.66 62.5779 371.67C75.1179 383 89.2879 383.49 117.828 388.56C117.828 388.56 132.398 344.43 78.2479 344.4Z"
            fill="#FEBD9D"
        />
        <path
            d="M205.298 395.359C192.628 398.359 165.628 395.919 149.448 394.119C145.228 393.639 141.738 393.219 139.448 392.949C137.668 392.739 136.588 392.619 136.448 392.639C105.018 395.559 101.118 388.689 87.8579 371.709C74.5979 354.729 78.0679 345.439 78.2479 344.399C102.788 342.399 144.848 344.239 166.158 346.929L178.158 349.109C183.378 352.157 187.978 356.16 191.718 360.909C201.528 373.259 204.438 388.289 205.298 395.359Z"
            fill="#FED81D"
        />
        <path
            d="M118.418 387.859C128.598 390.859 139.418 392.949 139.418 392.949"
            stroke="#F7AD2B"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M205.298 395.36C192.628 398.36 165.628 395.92 149.448 394.12C157.008 388.52 165.038 388.74 173.968 390.41C192.368 393.84 195.878 387.25 191.458 380.24C187.578 374.06 185.918 365.32 191.718 360.91C201.528 373.26 204.438 388.29 205.298 395.36Z"
            fill="#FCC71F"
        />
        <path
            d="M250.118 352.149C238.968 357.879 231.298 345.519 226.238 333.859C225.238 331.509 224.298 329.209 223.488 327.069C220.028 318.069 214.488 296.899 212.638 279.069C211.118 264.559 212.118 252.219 218.758 250.399C226.668 248.229 235.448 251.729 240.608 266.459C241.63 269.461 242.439 272.532 243.028 275.649C247.778 300.039 261.718 346.179 250.118 352.149Z"
            fill="#C3DCF8"
        />
        <path
            d="M226.198 333.859C225.198 331.509 224.258 329.209 223.448 327.069C220.758 320.069 216.778 305.669 214.298 291.219"
            stroke="#83ADDF"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M240.618 266.459C233.859 266.854 227.299 268.893 221.508 272.399C218.344 274.344 215.362 276.569 212.598 279.049C211.118 264.559 212.118 252.219 218.758 250.399C226.678 248.229 235.458 251.729 240.618 266.459Z"
            fill="#D0E5F7"
        />
        <path
            d="M205.888 218.78C202.058 226.13 172.678 217.78 172.678 217.78C167.908 216.39 165.518 214.509 165.678 211.619C165.838 208.729 170.328 208.619 169.168 205.869C168.582 204.528 168.243 203.092 168.168 201.63C168.088 198.99 169.078 195.959 172.948 193.509C179.748 189.189 189.088 194.989 189.088 194.989C189.088 194.989 193.358 191.42 201.088 195.39C204.998 197.39 207.158 201.489 207.828 205.989C208.486 210.351 207.81 214.81 205.888 218.78Z"
            fill="#DD6819"
        />
        <path
            d="M205.888 218.779C202.058 226.129 172.678 217.779 172.678 217.779C167.908 216.389 165.518 214.509 165.678 211.619C165.838 208.729 170.328 208.619 169.168 205.869C168.582 204.527 168.243 203.091 168.168 201.629C174.498 194.949 188.968 194.129 190.528 200.359C192.308 207.449 195.678 218.709 207.838 205.989C208.493 210.351 207.814 214.81 205.888 218.779Z"
            fill="#CC5E0D"
        />
        <path
            d="M188.058 198.78C193.598 195.02 198.748 197.86 200.188 202C202.278 208 195.188 214.29 202.278 219.64"
            stroke="#FF79A0"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M171.908 202.729C172.408 197.109 181.088 191.729 189.108 194.949"
            stroke="#FF79A0"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M201.678 227.38C201.678 227.38 193.628 235.2 184.858 237.72C176.088 240.24 168.858 235.01 169.558 222.6C169.558 222.39 169.558 222.18 169.558 221.98C169.678 220.47 169.858 218.98 170.098 217.58C171.948 206.88 177.508 199.14 186.738 202.04C189.568 202.92 186.918 212.91 188.818 217.41C188.93 217.687 189.071 217.952 189.238 218.2C189.87 219.122 190.713 219.88 191.698 220.41C194.818 222.14 199.078 221.75 200.448 219.11C202.448 215.29 207.888 213.44 209.778 219.87C211.188 224.48 206.118 228.66 201.678 227.38Z"
            fill="#FEBD9D"
        />
        <path
            d="M191.738 220.41C190.848 221.471 189.682 222.264 188.368 222.7C185.778 223.7 183.278 223.37 182.788 222.06C182.298 220.75 184.008 218.9 186.608 217.94C187.33 217.662 188.087 217.484 188.858 217.41C188.97 217.688 189.11 217.952 189.278 218.2C189.91 219.123 190.753 219.881 191.738 220.41Z"
            fill="#F4AF90"
        />
        <path
            d="M172.228 222C171.778 222.66 170.778 222.84 169.558 222.6C169.558 222.39 169.558 222.18 169.558 221.98C169.678 220.47 169.858 218.98 170.098 217.58C172.008 219.07 172.928 220.95 172.228 222Z"
            fill="#F4AF90"
        />
        <path
            d="M173.858 214.999C173.858 215.218 173.923 215.433 174.045 215.615C174.167 215.798 174.34 215.94 174.543 216.024C174.746 216.108 174.969 216.13 175.184 216.087C175.4 216.045 175.598 215.939 175.753 215.784C175.908 215.628 176.014 215.431 176.057 215.215C176.099 215 176.077 214.777 175.993 214.574C175.909 214.371 175.767 214.198 175.585 214.076C175.402 213.954 175.187 213.889 174.968 213.889C174.674 213.889 174.391 214.006 174.183 214.214C173.975 214.422 173.858 214.704 173.858 214.999Z"
            fill="#683F1D"
        />
        <path
            d="M180.008 216.69C180.008 216.984 180.125 217.267 180.333 217.475C180.541 217.683 180.823 217.8 181.118 217.8C181.412 217.8 181.695 217.683 181.903 217.475C182.111 217.267 182.228 216.984 182.228 216.69C182.228 216.396 182.111 216.113 181.903 215.905C181.695 215.697 181.412 215.58 181.118 215.58C180.823 215.58 180.541 215.697 180.333 215.905C180.125 216.113 180.008 216.396 180.008 216.69Z"
            fill="#683F1D"
        />
        <path
            d="M201.878 222.749C203.068 219.749 205.688 218.329 207.658 219.749"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M202.278 221.89C205.458 219.46 207.658 224.89 202.558 224.89"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M170.908 209.629C170.608 211.079 176.818 213.059 177.408 210.159C177.828 208.069 171.238 208.069 170.908 209.629Z"
            fill="#DD6819"
        />
        <path
            d="M181.168 210.559C180.488 213.079 187.548 214.399 188.308 212.699C188.958 211.259 181.928 207.699 181.168 210.559Z"
            fill="#DD6819"
        />
        <path
            d="M174.748 221.8C173.378 219.64 174.098 218.04 175.898 218.04C178.118 218.04 178.838 216.04 178.738 213.24"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M174.708 224C177.558 224.35 181.348 222.59 181.348 222.59C181.348 222.59 181.168 227.59 178.028 228.03C174.888 228.47 174.708 224 174.708 224Z"
            fill="white"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M97.638 194.561C94.708 192.681 91.338 193.201 90.348 195.791C88.918 199.531 91.608 202.051 94.628 203.031L97.638 194.561Z"
            fill="#F9F9F9"
            stroke="#DDDDDD"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M133.018 219.64C137.798 221.59 140.388 222.72 141.178 222.1C141.968 221.48 145.618 213.1 145.228 212.2C144.838 211.3 137.348 207.25 137.348 207.25L133.018 219.64Z"
            fill="#DDDDDD"
            stroke="#EDEDED"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M106.488 255.249C107.238 252.059 117.968 249.469 121.588 248.869V259.639C121.588 259.639 110.248 261.999 106.488 255.249Z"
            fill="#A7CAF0"
        />
        <path
            d="M111.028 260.04C112.878 251.12 114.128 249.04 113.548 247.92C112.968 246.8 109.218 243.14 108.768 239.39C108.318 235.64 112.288 229.34 113.688 227.39C115.088 225.44 117.038 223.95 123.168 224.92C129.298 225.89 131.698 226.73 131.768 229.76C131.838 232.79 129.058 253.28 129.058 253.28C129.058 253.28 124.208 268.56 111.028 260.04Z"
            fill="#FEBD9D"
        />
        <path
            d="M118.658 215.34C117.388 221.34 114.048 231.39 112.798 236.21C111.548 241.03 112.358 243.63 116.348 244.44C120.338 245.25 122.768 238.35 123.578 233.82C124.388 229.29 126.578 218.04 126.578 218.04L118.658 215.34Z"
            fill="#F9F9F9"
            stroke="#DDDDDD"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M100.868 327.619C114.468 338.859 123.048 315.439 126.198 294.889C128.159 281.107 129.115 267.2 129.058 253.279C129.058 253.279 117.718 256.439 106.488 255.279C103.028 271.939 89.7778 318.459 100.868 327.619Z"
            fill="#C3DCF8"
        />
        <path
            d="M119.418 319.439C122.608 312.589 124.878 303.489 126.198 294.889C128.159 281.107 129.115 267.2 129.058 253.279"
            stroke="#83ADDF"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M134.888 219.88C133.328 220.5 129.778 220.68 125.148 220.13C117.291 219.148 109.662 216.817 102.598 213.24L112.258 192.24C119.524 194.338 126.391 197.63 132.578 201.98C136.078 204.43 138.578 206.75 139.448 208.28C140.318 209.81 138.568 218.41 134.888 219.88Z"
            fill="#F9F9F9"
            stroke="#DDDDDD"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M134.888 219.879C133.328 220.499 129.778 220.679 125.148 220.129C129.798 214.889 133.088 209.199 132.578 202.029C136.078 204.479 138.578 206.799 139.448 208.329C140.318 209.859 138.568 218.409 134.888 219.879Z"
            fill="#F9F9F9"
            stroke="#EDEDED"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M104.568 214.76C107.148 214 115.078 198.54 113.938 194C112.798 189.46 103.098 179 103.098 179C103.098 179 90.7177 200.46 88.4277 216.33C88.4277 216.35 100.818 215.87 104.568 214.76Z"
            fill="#DDDDDD"
            stroke="#EDEDED"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M89.4677 186.38L85.6577 180.1" stroke="#DDDDDD" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M74.5679 190.75L81.2879 193.47" stroke="#DDDDDD" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M73.7778 204.311L80.7278 203.061" stroke="#DDDDDD" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M107.688 234.749C110.468 233.319 118.128 241.749 113.468 242.289C110.708 242.629 103.598 236.849 107.688 234.749Z"
            fill="#FEBD9D"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M108.288 230.07C111.078 228.64 119.288 237.14 114.678 237.72C111.908 238.06 104.198 232.17 108.288 230.07Z"
            fill="#FEBD9D"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M109.438 225.321C112.438 223.781 121.308 232.931 116.298 233.551C113.328 233.911 105.038 227.561 109.438 225.321Z"
            fill="#FEBD9D"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M111.118 221.061C114.028 219.361 122.688 228.821 118.288 230.791C116.218 231.721 106.868 223.561 111.118 221.061Z"
            fill="#FEBD9D"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M123.568 230.63C122.858 229.7 116.268 226.57 117.038 224.07C117.808 221.57 125.678 224.14 129.358 225.32C133.038 226.5 132.128 228.79 131.208 236.21C131.208 236.21 127.958 236.4 123.568 230.63Z"
            fill="#FEBD9D"
        />
        <path
            d="M123.568 230.63C122.858 229.7 116.268 226.57 117.038 224.07C117.808 221.57 125.678 224.14 129.358 225.32C133.038 226.5 132.128 228.79 131.208 236.21"
            stroke="#EF8A66"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default PreviewIllustration;
